import React, { Component } from 'react'

class Auto extends Component {

  componentDidMount() {
  }

  render() {
    return (
      <React.Fragment>
      </React.Fragment>
    )
  }
}
export default Auto
